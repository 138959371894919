import React from 'react';

//Components
import Typography from "../../components/ui/Typography";
import Icons from "../../components/icons/Icons";

const styles = {
    headerNav: {
        display: 'flex',
        backgroundColor: 'black',
        height: '2em',
        flexDirection: 'row',
        justifyContent: "flex-end",
        alignItems: "center",
        position: "sticky",
        top: 0
    },
    icon: {
        color: 'white',
    },
    link: {
        color: "inherit",
        textDecoration: "inherit",
        cursor: 'pointer'
    },
};

export default function HeaderNav(props){

    return (
        <React.Fragment>
            <div style={styles.headerNav}>
                {/*<img src={logo} className={classes.logo} alt={'MOTH Logo'}/>*/}
                <div>{Icons({
                    name: "call",
                    classes: {icon: {color: "white"}},
                    size: 0.8
                }).icon}</div>
                <div>&nbsp;</div>
                <a href={`tel:${props.address.tel}`} style={styles.link}>
                    <Typography color={'white'} variant={'nav'}>
                        {props.address.tel}</Typography>
                </a>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>{Icons({
                    name: "mail",
                    classes: {icon: {color: "white"}},
                    size: 0.8,
                }).icon}</div>
                <div>&nbsp;</div>
                <a href={`mailto:${props.address.email}`} style={styles.link}>
                <Typography color={'white'} variant={'nav'}>{props.address.email}</Typography>
                </a>

                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>{Icons({
                    name: "mail",
                    classes: {icon: {color: "white"}},
                    size: 0.8,
                }).icon}</div>
                <div>&nbsp;</div>
                <a href={`mailto:${props.address.email2}`} style={styles.link}>
                    <Typography color={'white'} variant={'nav'}>{props.address.email2}</Typography>
                </a>
                <div>&nbsp;</div>
            </div>
        </React.Fragment>
    )
}
