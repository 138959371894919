import React from 'react';
import Header from "../layout/Header";
import Typography from "../../components/ui/Typography";
import Background from '../../assets/images/bakoven/MainImage.jpg';
import Footer from "../layout/Footer";
import CottageLocation from "../cottages/CottageLocation";
import CottageOverview from "../cottages/CottageOverview";
import ImageSlider from "../../components/sliders/ImageSlider";
import HeaderNav from "../layout/HeaderNav";
import CottageBooking from "../cottages/CottageBooking";

import image1 from "../../assets/images/bakoven/JJT_5278_edit.jpg";
import image2 from "../../assets/images/bakoven/JJT_5290_edit.jpg";
import image3 from "../../assets/images/bakoven/JJT_5300_edit.jpg";
import image4 from "../../assets/images/bakoven/JJT_5301_edit.jpg";
import image5 from "../../assets/images/bakoven/JJT_5305_edit.jpg";
import image6 from "../../assets/images/bakoven/JJT_5311_edit.jpg";
import image7 from "../../assets/images/bakoven/JJT_5534_edit.jpg";
import image8 from "../../assets/images/bakoven/JJT_5554_edit.jpg";
import image9 from "../../assets/images/bakoven/JJT_5570_edit.jpg";
import image10 from "../../assets/images/bakoven/JJT_5599_edit.jpg";
import bookingForm from "../../assets/files/Bakoven-Cottage-Booking-Form-2024v1.9.pdf";
import CottageRules from '../cottages/CottageRules';
import CottageRules2 from '../cottages/CottageRules2';

import './Landing.scss';

const styles = {
    sectionContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    section1: {
        display: 'flex',
        height: '50em',
        width: '100vw',
        justifyContent: "center",
        backgroundImage: 'url(' + Background + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        backgroundPosition: "top left",
        backgroundBlendMode: "darken",
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: '0.5em',
        // }
    },
    evenColumns: {
        display: 'flex',
        flexWrap: 'wrap',
        '& *': {
            flexBasis: '30%',
            flexGrow: '1',
            flexShrink: '1',
            margin: '0.2em',
            // [theme.breakpoints.down('sm')]: {
            //     flexBasis: '50%'
            // }
        },
        gap: '1em',
    },
    slider: {
        width: '100%',
        height: '100%',
    },
    playerWrapper: {
        width: 'auto', // Reset width
        height: 'auto', // Reset height
        position: 'relative' // Set to relative
    },
    reactPlayer: {
        paddingTop: '56.25%', // Percentage ratio for 16:9
        position: 'relative' // Set to relative
    },
    gallery: {
        backgroundColor: '#B6985C'
    }
};

const cottageData = {
    address: {
        street: '20 & 21 Beta Road',
        suburb: 'Bakoven',
        city: 'Cape Town',
        tel: '+27(0)82 572 5561',
        email: 'mothcottage@gmail.com',
        email2: 'mothbilly.hill@gmail.com'
    },
    calendar: [{
        name: 'ElAlamein',
        url: 'https://calendar.google.com/calendar/embed?src=f6v39tplj7hfp3m0kia71jkkd0%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showTz=0&amp;showCalendars=0&amp;showTitle=1&amp;showTabs=0&amp;showPrint=0',
    }, {
        name: 'DelvilleWood',
        url: 'https://calendar.google.com/calendar/embed?src=92m93d72huql1vfsidig3g7pgc%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showCalendars=0&amp;showTitle=1&amp;showTabs=0&amp;showPrint=0'
    }],
    attractions: [
        'Beaches – sun, sea & ski',
        'Yachting and boat trips',
        'Mountains – hiking',
        'Cycling',
        'Fishing',
        'Restaurants, shops, and curios',
        'Table Mountain – cable car'
    ],
    gallery: {
        videoUrl: 'https://youtu.be/yI3kMr-cVfg',
        images: [
            {type: 'videos', url: 'https://www.youtube.com/watch?v=Fl-l1yJHqhE'},
            {type: 'image', url: image1},
            {type: 'image', url: image2},
            {type: 'image', url: image3},
            {type: 'image', url: image4},
            {type: 'image', url: image5},
            {type: 'image', url: image6},
            {type: 'image', url: image7},
            {type: 'image', url: image8},
            {type: 'image', url: image9},
            {type: 'image', url: image10},
        ]
    }
}

export default function Landing(){

    const matches = window.matchMedia('(max-width: 768px)').matches;

    const TitleDivider = () => {
        return (
            <React.Fragment>
                <div style={{borderBottom: '1px solid lightgray', marginLeft: '10px'}}></div>
                <div style={{border: '1px solid #B6985C', marginLeft: '10px', width: '4em', position: 'relative', top: -6}}></div>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <HeaderNav {...cottageData}></HeaderNav>
            <Header data-testid={"main-header"}/>

            <div style={styles.sectionContainer}>
                {/*SECTION - MAIN IMAGE*/}
                <div style={styles.section1}>
                    {matches ? (
                        <Typography variant={'h2'} color={'white'} fontWeight={"bold"}>
                            BAKOVEN COTTAGES
                        </Typography>) : ( <Typography variant={'h3'} color={'white'} fontWeight={"bold"}>
                        BAKOVEN COTTAGES
                    </Typography>)}
                </div>
            </div>
            {/*SECTION - ACCOMMODATION*/}
            <div style={styles.sectionContainer} id={'accommodation'}>
                <div className={'even-columns'}>
                    <div>
                        <Typography variant={'h4'}>
                            Self Catering
                        </Typography>
                        <TitleDivider/>
                        <CottageOverview></CottageOverview>
                        <Typography variant={'h4'}>
                            House Rules
                        </Typography>
                        <TitleDivider/>
                        <CottageRules></CottageRules>
                    </div>
                    <div>
                        <Typography variant={'h4'}>
                            House Rules
                        </Typography>
                        <TitleDivider/>
                        <CottageRules2></CottageRules2>
                    </div>
                    <div>
                        <Typography variant={'h4'}>
                            Location
                        </Typography>
                        <TitleDivider/>
                        <CottageLocation {...cottageData}></CottageLocation>
                    </div>
                </div>
            </div>
            <div style={{alignItems: 'center', margin: '1em', paddingTop: '2em'}} >
                <div id={'gallery'} >
                    <Typography variant={'h4'}>
                        Gallery
                    </Typography>
                    <div>
                        <div style={{borderBottom: '1px solid lightgray', marginTop: '10px'}}></div>
                        <div style={{border: '1px solid #B6985C', width: '4em', position: 'relative', top: -3}}></div>
                    </div>
                </div>
                <div style={styles.gallery}>
                    <ImageSlider {...cottageData.gallery}></ImageSlider>
                </div>
            </div>
            {/*SECTION - BOOKINGS*/}
            <div style={styles.sectionContainer} id={'bookings'}>
                <div className={'even-columns'}>
                    <div>
                        <Typography variant={'h4'}>
                            How to Book
                        </Typography>
                        <TitleDivider/>
                        <Typography variant={'label'}>
                            To book a cottage at Bakoven, you need to be in good standing order with your Shellhole.
                        </Typography>
                        <Typography variant={'label'}>
                            Please ensure you have the form signed by your OLD BILL
                        </Typography>
                        <Typography variant={'label'}>
                            We no longer accept facsimiles.
                        </Typography>
                        <Typography variant={'label'}>
                            <div>
                                <ol>
                                    <li>Download the latest booking form <a href={bookingForm} target='_blank' rel="noreferrer"><b>HERE</b></a>.</li>
                                    <li>Complete and sign the form and then email it, together with proof of payment, to <a href={`mailto:${cottageData.address.email}`} style={styles.link} >{cottageData.address.email}</a></li>
                                    <li> Confirmation, instructions, details etc will be emailed back to the client.</li>
                                    <li> Details of how to get to the cottages can be found in the Location section elsewhere on the page</li>
                                </ol>
                            </div>
                        </Typography>
                    </div>
                    <div>
                        {/*El Alamein*/}
                        <Typography variant={'h4'}>
                            Calendar
                        </Typography>
                        <TitleDivider/>
                        <CottageBooking url='https://calendar.google.com/calendar/embed?src=f6v39tplj7hfp3m0kia71jkkd0%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showTz=0&amp;showCalendars=0&amp;showTitle=1&amp;showTabs=0&amp;showPrint=0'></CottageBooking>
                    </div>
                    <div>
                        {/*Delville Wood*/}
                        <Typography variant={'h4'}>
                            Calendar
                        </Typography>
                        <TitleDivider/>
                        <CottageBooking url="https://calendar.google.com/calendar/embed?src=92m93d72huql1vfsidig3g7pgc%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showCalendars=0&amp;showTitle=1&amp;showTabs=0&amp;showPrint=0"></CottageBooking>
                    </div>
                </div>
            </div>

            <Footer/>
        </React.Fragment>
    )
}
