import React from 'react';
import Typography from "../../components/ui/Typography";

export default function CottageRules2() {

    return (
        <React.Fragment>
            <div>
                <Typography variant={'h5'}>
                    Smoking policy:
                </Typography>
                <Typography variant={'label'}>
                    The cottage is a smoke-free zone inside. Guests can only smoke in outdoor areas such as the braai area. Discovering guests have been smoking inside the property will also result in loss of the security deposit
                </Typography>
                <Typography variant={'h5'}>
                    Braai Area:
                </Typography>
                <Typography variant={'label'}>
                    Is there anything better than tucking into a braai on a sunny afternoon with friends and family, we don’t think so! <b>GUESTS SHOULD SUPPLY THEIR OWN BRAAI GRID AND WOOD.</b> Be sure to clean the fireplace before departing. Please only use the barbecue until 22h00 to keep noise and smells to a minimum for our neighbours. Please use the designated barbecue utensils and not the regular kitchen tongs. Please put tables/benches back in original position found
                </Typography>
                <Typography variant={'h5'}>
                    Breakage:
                </Typography>
                <Typography variant={'label'}>
                    Always report any accidental damages or breakages in a timely manner, especially before check-out.
                    In case any damage to the property or its appliances is not covered by our insurance, the guest will pay for the repair costs from the security deposit.
                </Typography>
                <Typography variant={'h5'}>
                    Security:
                </Typography>
                <Typography variant={'label'}>
                    Our cottages are fitted with burglar alarms that are linked to an armed response monitoring company but while
                    we have taken the necessary measures to make our vacation rental safe for incoming guests,
                    you also have a role to play in ensuring the safety and security of your party.
                    Any time guests leave the vacation rental, it is their responsibility to ensure all windows and doors are closed and locked to maintain security and
                    prevent rain and water damage.
                    We are not responsible for the loss of any personal belongings or valuables.
                    In case you misplace something in the house, please let us know so we can look out for the item during the changeover.
                </Typography>
                <Typography variant={'h5'}>
                    Departure:
                </Typography>
                <Typography variant={'label'}>
                    Please fill in our Guest Book before departing, your input it will help us providing a better service in the future.
                    This is your vacation time and we hope you make the most of our cottage and its amenities and your opportunity to relax and visit the surrounding area.
                    Please empty the refrigerator before you leave. Do not switch the refrigerator off.
                    Please ensure that dishes, pots and pans, cutlery, toaster, kettles etc. are clean and dried before storing them. On departure we ask that you close all windows, so preventing possible storm damages. Please also consider unplugging all electrical items (except the refrigerator and stove)
                    The custodian reserves the right to make a charge to cover additional cleaning costs if the client leaves the property in an unacceptable condition.
                </Typography>
            </div>
        </React.Fragment>
    )
}
