import React from 'react';
import Typography from "../../components/ui/Typography";

export default function CottageOverview(){

    return (
        <React.Fragment>
            <div>
                <div>
                    <Typography variant={'label'}>The Bakoven Cottages are ideally situated close to the scenic Atlantic Seaboard, with easy access to some of the famous tourist spots of the Cape Peninsula.</Typography>
                    <Typography variant={'label'}>Two Cottages that are spacious and comfortable with 3 bedrooms (main bedroom – comfortable twin beds together or apart, two rooms with separate single beds, lots of cupboard space.</Typography>
                    <Typography variant={'label'}>Ablutions consist of a family bathroom (shower) and separate toilet.</Typography>
                    <Typography variant={'label'}>Linen, including bath towels is supplied for 6 persons.</Typography>
                    <Typography variant={'label'}>A ’starter pack’ of toilet paper, washing up liquid and dish cloths is supplied.
                        The living area has a beautiful sea view and dining room. The fully equipped kitchen leads onto a braai area, with benches and umbrellas.</Typography>
                    <Typography variant={'label'}>WiFi is available in both cottages.</Typography>
                    <Typography variant={'label'} fontWeight="bold">PLEASE SUPPLY OWN BEACH TOWELS.</Typography>
                </div>
            </div>
        </React.Fragment>
    )
}
