import React from 'react';
import Typography from "../../components/ui/Typography";

export default function CottageLocation(props){

    const {address} = props;

    return (
        <React.Fragment>
            <div>
                <div>
                    <ul>
                        <li>
                            <Typography variant={'label'}>{address?.street || ''}</Typography>
                            <Typography variant={'label'}>{address?.suburb || ''}</Typography>
                            <Typography variant={'label'}>{address?.city || ''}</Typography>
                        </li>
                        <li>
                            <Typography variant={'label'}>{address?.tel || ''}</Typography>
                        </li>
                    </ul>
                </div>
                <div></div>
                <div>
                    <div style={{height: '20em', margin: '5px'}}>
                        <iframe title={'Cottage Location'} loading="lazy" style={{border: '0', margin: '0', width: '100%', height: '100%'}}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.208581799519!2d18.371207315704726!3d-33.961477980630704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc66495188ce41%3A0xfdc2afe8b97e964d!2s21+Beta+Rd%2C+Bakoven%2C+Cape+Town%2C+8005!5e0!3m2!1sen!2sza!4v1536002008577"
                                allowFullScreen="allowfullscreen">
                        </iframe>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
