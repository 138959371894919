import React from 'react';
import logo from '../../assets/images/gif/logo.gif';
import Grid from '@mui/material/Grid2';

//Components
import Typography from "../../components/ui/Typography";

import '../landing/Landing.scss';

const styles = {
    header: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        height: '75px',
        backgroundColor: 'white',
        margin: '2em'
    },
    headerMain: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        // [theme.breakpoints.down('sm')]: {
             justifyContent: 'center',
        // }
    },
    headerMenu: {
        display: 'flex',
        flex: 1,
        //justifyContent: 'flex-end',
        alignItems: 'right',
        backgroundColor: 'white',
        marginRight: '5em',
        // [theme.breakpoints.down('sm')]: {
             justifyContent: 'center',
        // }
    },
    spacer: {
        borderRight: '1px solid gray',
        margin: '0 10px 0 10px',
        // [theme.breakpoints.down('sm')]: {
        //     margin: '0 6px 0 6px',
        // }
    },
    headerTitle: {
        margin: 5
    },
    icon: {
        color: 'white'
    },
    separator: {
        width: '14em',
        borderBottom: 'solid 1px',
        margin: '10px 0 10px 0'
    },
    link: {
        color: "inherit",
        textDecoration: "inherit",
        cursor: 'pointer'
    },
    logo: {
        width: '80px',
        height: '80px',
        marginRight: '10px'
    }
};

export default function Header(){

    const matches = window.matchMedia('(max-width: 256px)').matches;

    return(
        <React.Fragment>
            <Grid container justifyContent={"space-between"} style={styles.header}>
                <Grid item xs={12} sm={4}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <img src={logo} style={styles.logo} alt={'MOTH'}/>
                        <div className={'headerMain'}>
                            <div>
                                <Typography variant={'h3'} fontFamily={'DINPro-Light'} style={styles.headerTitle} >
                                    Moth Cottages
                                </Typography>
                            </div>
                            <div style={styles.separator}></div>
                            <div>
                                <Typography variant={'label'}>
                                    L U X U R Y&nbsp;&nbsp;&nbsp;G E T A W A Y S
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <div className={'headerMenu'}>
                        <div>
                            {matches ? (<Typography variant={'label'}>
                                <a href={'#accommodation'} color={'inherit'} style={styles.link} rel={'noreferrer'}>
                                    ACCOMMODATION
                                </a>
                            </Typography>) : ( <Typography variant={'button'}>
                                <a href={'#accommodation'} color={'inherit'} style={styles.link} rel={'noreferrer'}>
                                    ACCOMMODATION
                                </a>
                            </Typography>)}
                        </div>
                        <div style={styles.spacer}></div>
                        <div>
                            {matches ? (<Typography variant={'label'}>
                                <a href={'#gallery'} color={'inherit'} style={styles.link} rel={'noreferrer'}>
                                    GALLERY
                                </a>
                            </Typography>) : ( <Typography variant={'button'}>
                                <a href={'#gallery'} color={'inherit'} style={styles.link} rel={'noreferrer'}>
                                    GALLERY
                                </a>
                            </Typography>)}
                        </div>
                        <div style={styles.spacer}></div>
                        <div>
                            {matches ? (<Typography variant={'label'}>
                                <a href={'#bookings'} color={'inherit'} style={styles.link} rel={'noreferrer'}>
                                    BOOKINGS
                                </a>
                            </Typography>) : ( <Typography variant={'button'}>
                                <a href={'#bookings'} color={'inherit'} style={styles.link} rel={'noreferrer'}>
                                    BOOKINGS
                                </a>
                            </Typography>)}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
