import React from 'react';

// Routes
import {Route, Switch} from 'react-router-dom';
import RoutePaths from './RoutePaths';

//Modules
import Landing from "../modules/landing/Landing";
import Test from "../modules/test/Test";

export const Routes = () => {
    return (
        <Switch>
            {/*AUTHENTICATE*/}
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={RoutePaths.AUTHENTICATE}*/}
            {/*  render={route => (*/}
            {/*    <Landing route={route} authenticate={true}/>*/}
            {/*  )}*/}
            {/*/>*/}

            {/*DEFAULT*/}
            <Route
                exact
                path={RoutePaths.DEFAULT}
                render={route => (
                    <Landing route={route}/>
                )}
            />

            {/*LANDING*/}
            <Route
                exact
                path={RoutePaths.HOME}
                render={route => (
                    <Landing route={route}/>
                )}
            />

            <Route
                exact
                path={RoutePaths.TEST}
                render={route => (
                    <Test route={route}/>
                )}
            />
        </Switch>
    )
}
