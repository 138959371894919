import React from 'react';

//Material-UI
// import {
//   Grid,
//   makeStyles,
// } from '@material-ui/core';

//Components
import Typography from "../../components/ui/Typography";
import Grid from '@mui/material/Grid2';

const styles = {
  footer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100px',
    backgroundColor: 'black',
  },
  headerMain: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'flex-start'
    alignItems: 'flex-end'
  },
  headerMenu: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    //height: '75px',
    backgroundColor: 'white',
    //width: '100%'
  },
  headerTitle: {
    margin: 5
  },
  icon: {
    color: 'white'
  },
  separator: {
    width: '14em',
    borderBottom: 'solid 1px',
    margin: '10px 0 10px 0'
  },
  footerText: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
  }
};

export default function Footer(){

  return(
    <React.Fragment>
      <Grid container justifyContent={"space-between"} style={styles.footer}>
        <Grid item xs={12}>
          <div style={styles.footerText}>
            <div>
              <Typography variant={'button'} color={'white'}>
                @Copyright {new Date().getFullYear()} Pro Patria Shellhole
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
