import React from 'react';

import { IoIosCall } from "react-icons/io";

import {
    FaBell,
    FaBug,
    FaChevronDown,
    FaChevronRight,
    FaCog,
    FaLightbulb,
    FaBoxes,
    FaMoon,
    FaPlus,
    FaQuestionCircle,
    FaRegClipboard,
    FaRegFile,
    FaRegFileAudio,
    FaRegFilePdf,
    FaSun,
    FaUndo,
    FaExchangeAlt,
    FaRegEye,
    FaUserAlt,
    FaArrowUp,
    FaArrowDown
} from 'react-icons/fa';

import { GiCheckMark } from "react-icons/gi";

import { GrMail } from "react-icons/gr";

import {
    MdApps,
    MdClose,
    MdCloudUpload,
    MdContentCopy,
    MdDone,
    MdEdit,
    MdInfoOutline,
    MdMoreHoriz,
    MdSend
} from 'react-icons/md';

import {
    RiPlayFill,
    RiSaveLine,
    RiTestTubeLine,
    RiHome3Line
} from 'react-icons/ri';

import {
    RiDeleteBinLine,
    RiFileExcel2Line,
    RiFilterLine
} from 'react-icons/ri';

import {
    HiDownload,
    HiOutlineViewList
} from 'react-icons/hi';

import {
    BiArrowToTop,
    BiDislike,
    BiLike
} from 'react-icons/bi';
import {GrUndo} from 'react-icons/gr';
import {BsGridFill} from 'react-icons/bs';
import {
    GiHamburgerMenu
} from 'react-icons/gi';

export default function Icons(props) {
    // Constants
    const size = props.size ? props.size * 16 : 16;

    //Icons
    const iconList = {
        add: {
            name: 'add',
            icon: <FaPlus data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Add"/>
        },
        ascending: {
            name: 'ascending',
            icon: <FaArrowUp data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Ascending"/>
        },
        apps: {
            name: 'apps',
            icon: <MdApps data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Apps"/>
        },
        approve: {
            name: 'approve',
            icon: <BiLike data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Approve"/>
        },
        audio: {
            name: 'audio',
            icon: <FaRegFileAudio data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Audio"/>
        },
        bell: {
            name: 'bell',
            icon: <FaBell data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Bell"/>
        },
        // bellWithBadge: {
        //     name: 'bellWithBadge',
        //     icon:
        //         <Badge data-testid={props.testId} variant="dot" classes={{badge: props.classes && props.classes.badge}}>
        //             <FaBell size={size} className={props.classes && props.classes.icon} title="Bell"/>
        //         </Badge>
        // },
        bug: {
            name: 'bug',
            icon: <FaBug data-testid={props.testId} variant="dot" classes={{badge: props.classes && props.classes.badge}} title="Bugs"/>
        },
        call: {
            name: 'call',
            icon: <IoIosCall data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="call" />
        },
        checkmark: {
            name: 'checkmark',
            icon: <GiCheckMark data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="checkmark" />
        },
        chevronDown: {
            name: 'chevronDown',
            icon: <FaChevronDown data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Down"/>
        },
        chevronRight: {
            name: 'chevronRight',
            icon: <FaChevronRight data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Down"/>
        },
        close: {
            name: 'close',
            icon: <MdClose data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Close"/>
        },
        clone: {
            name: 'clone',
            icon: <MdContentCopy data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Clone"/>
        },
        copy: {
            name: 'copy',
            icon: <FaRegClipboard data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Copy"/>
        },
        darkMode: {
            name: 'darkMode',
            icon: <FaMoon data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Dark Mode"/>
        },
        descending: {
            name: 'descending',
            icon: <FaArrowDown data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Descending"/>
        },
        delete: {
            name: 'delete',
            icon: <RiDeleteBinLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Delete"/>
        },
        deliveryOrderDashboard: {
            name: 'deliveryOrderDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Deliver Order Dashboard"/>
        },
        document: {
            name: 'document',
            icon: <FaRegFile data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Document"/>
        },
        done: {
            name: 'done',
            icon: <MdDone data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Done"/>
        },
        download: {
            name: 'download',
            icon: <HiDownload data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Download"/>
        },
        edit: {
            name: 'edit',
            icon: <MdEdit data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Edit"/>
        },
        excel: {
            name: 'excel',
            icon: <RiFileExcel2Line data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Export as Excel Document"/>
        },
        faq: {
            name: 'faq',
            icon: <FaQuestionCircle size={size} className={props.classes && props.classes.icon} title="FAQ"/>
        },
        financeDashboard: {
            name: 'financeDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Finance Dashboard"/>
        },
        grid: {
            name: 'grid',
            icon: <BsGridFill data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Grid"/>
        },
        hamburger: {
            name: 'hamburger',
            icon: <GiHamburgerMenu data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Hamburger"/>
        },
        home: {
            name: 'home',
            icon: <RiHome3Line data-testid={props.testId} size={size} className={props.classes && props.classes.icon}
                               title="Erflist"/>
        },
        info: {
            name: 'info',
            icon: <MdInfoOutline data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title={'Info'}/>
        },
        inventoryDashboard: {
            name: 'inventoryDashboard',
            icon: <FaBoxes data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title={'Inventory Dashboard'}/>
        },
        lightMode: {
            name: 'lightMode',
            icon: <FaSun data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Light Mode"/>
        },
        mail: {
            name: 'mail',
            icon: <GrMail data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Mail"/>
        },
        moreHorizontal: {
            name: 'moreHorizontal',
            icon: <MdMoreHoriz data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="More"/>
        },
        pdf: {
            name: 'pdf',
            icon: <FaRegFilePdf data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Export as PDF Document"/>
        },
        play: {
            name: 'play',
            icon: <RiPlayFill data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Play"/>
        },
        productionDashboard: {
            name: 'productionDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Production Dashboard"/>
        },
        productsDashboard: {
            name: 'productsDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Product Dashboard"/>
        },
        productCatalogues: {
            name: 'productCatalogues',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Product Catalogues"/>
        },
        productMaster: {
            name: 'productMaster',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Product Master"/>
        },
        productOrigin: {
            name: 'productOrigin',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Product Origin"/>
        },
        profile: {
            name: 'profile',
            icon: <FaUserAlt data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Profile"/>
        },
        purchaseOrderDashboard: {
            name: 'purchaseOrderDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Purchase Order Dashboard"/>
        },
        reject: {
            name: 'reject',
            icon: <BiDislike data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Reject"/>
        },
        reset: {
            name: 'reset',
            icon: <FaUndo data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Reset"/>
        },
        reportsDashboard: {
            name: 'reportsDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Reports Dashboard"/>
        },
        save: {
            name: 'save',
            icon: <RiSaveLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Save"/>
        },
        filter: {
            name: 'filter',
            state: true,
            icon: <RiFilterLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Filter"/>
        },
        send: {
            name: 'send',
            icon: <MdSend data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Send"/>
        },
        settings: {
            name: 'settings',
            icon: <FaCog data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Settings"/>
        },
        shipmentsDashboard: {
            name: 'shipmentsDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Shipments Dashboard"/>
        },
        sourcingDashboard: {
            name: 'sourcingDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Sourcing Dashboard"/>
        },
        sourcingProducts: {
            name: 'sourcingProducts',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Sourcing Products"/>
        },
        sourcingSuppliers: {
            name: 'sourcingSuppliers',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Sourcing Suppliers"/>
        },
        suggestion: {
            name: 'suggestion',
            icon: <FaLightbulb data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Suggestion"/>
        },
        swap: {
            name: 'swap',
            icon: <FaExchangeAlt data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Swap"/>
        },
        toTop: {
            name: 'toTop',
            icon: <BiArrowToTop data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Back to top"/>
        },
        table: {
            name: 'table',
            icon: <HiOutlineViewList data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Table"/>
        },
        teamDashboard: {
            name: 'teamDashboard',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Team Dashboard"/>
        },
        test: {
            name: 'test',
            icon: <RiTestTubeLine data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Test"/>
        },
        view: {
            name: 'view',
            icon: <FaRegEye data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="View"/>
        },
        undo: {
            name: 'undo',
            icon: <GrUndo data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Undo"/>
        },
        upload: {
            name: 'upload',
            icon: <MdCloudUpload data-testid={props.testId} size={size} className={props.classes && props.classes.icon} title="Upload"/>
        }
    };

    return (
        iconList[props.name]
    );
}
